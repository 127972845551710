<template>
  <section
    id="get-in-touch"
    class="overflow-hidden"
  >
    <v-row
      class="info white--text"
      justify="center"
    >
      <v-col
        class="text-center"
        cols="12"
        md="6"
      >
        <base-heading class="mb-5">
          お問い合わせ
        </base-heading>
        <v-form
          name="contact"
          data-netlify="true"
        >
          <input
            type="hidden"
            name="form-name"
            value="contact"
          >
          <v-text-field
            v-model="name"
            name="name"
            color="info"
            label="名前"
            solo
            flat
          />
          <v-text-field
            v-model="email"
            name="email"
            color="info"
            label="メールアドレス"
            solo
            flat
          />
          <v-text-field
            v-model="title"
            name="title"
            color="info"
            label="タイトル"
            solo
            flat
          />
          <v-textarea
            v-model="message"
            name="message"
            color="info"
            label="問い合わせ内容"
            solo
            flat
          />
          <v-text-field
            v-show="false"
            v-model="botField"
            label="人間は入力しないでください"
          />
          <v-btn
            :disabled="submitted"
            class="mb-6"
            @click="submit"
          >
            送信
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </section>
</template>

<script>
  export default {
    name: 'ContactUs',
    data () {
      return {
        name: null,
        email: null,
        title: null,
        message: null,
        botField: '',
        submitted: false
      }
    },
    methods: {
      submit () {
        const params = new FormData()
        // 以下、ダミーフォームの各フォーム要素のnameと合わせる
        params.append('form-name', 'contact')
        params.append('name', this.name)
        params.append('email', this.email)
        params.append('title', this.title)
        params.append('message', this.message)
        params.append('bot-field', this.botField)
        const axiosConfig = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }

        this.axios.post(window.location.origin, params, axiosConfig)
          .then(() => {
            this.submitted = true
          })
        // 実際はresponseを使って画面側にフィードバックさせるが、ここでは仮にconsoleに出力
      }
    }
  }
</script>
